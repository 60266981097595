// Expresión regular para validar que un strign sea solo un texo
const soloTextoRegExp = /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/

/** CONVERTIR VALOR DE STRING A NUMBER
 * Función que convierte un valor de formato string a numerico de dos decimales
 * @param {String} numero Es el valor en formato string
 * @returns {Number}
 */
function convertirNumeroAFixed (numero) {
  return Number(parseFloat(numero).toFixed(2))
}

/**
 * @param {String} texto a comprobar
 * @returns {Boolean} Valor de la comprobación
 * @description Valida que el parametro enviado sea un string valido
 */
const validarTexto = texto => soloTextoRegExp.test(texto)

/**
 * Capitaliza la primera letra de una cadena
 * Función que convierte la primera letra de una cadena a mayúscula
 * @param {string} string
 * @return {string}
 */
function capitalizar (string) {
  if (string) {
    const textotoLowerCase = string.toLowerCase()
    const textoCapitalize = textotoLowerCase.replace(/(^\w|\s\w)/g, (m) =>
      m.toUpperCase()
    )
    const textoSinEspacios = textoCapitalize.trim().replace(/\s+/g, ' ')
    string = textoSinEspacios
    return string
  }
}
/**
 * @param {String} string a comprobar
 * @returns {String}  string capitalizado
 * @description Capitaliza las palabras de un string
 */
const capitalizarPalabras = string => {
  if (string) {
    const textoSinEspacios = string.trim().replace(/\s+/g, ' ')
    const arrayDePalabras = textoSinEspacios.split(' ')
    const arrayDePalabrasConFormato = arrayDePalabras.map(
      p => p.charAt(0).toUpperCase() + p.slice(1)
    )
    const stringConFormato = arrayDePalabrasConFormato.join(' ')
    return stringConFormato
  }
}

/**
 * Valida estado de un pedido
 * retorna la clase correspondiente a aplicar
 * @param {string} estado
 * @return {string}
 */
function asignarColorEstado (estado) {
  const estadoCapitilizado = capitalizar(estado)
  if (estado && estado.length) {
    switch (estadoCapitilizado) {
      case 'Auditado':
      case 'Completo':
      case 'Confirmado':
      case 'Procesada':
      case 'Solvente':
      case 'Despachado':
        return 'texto-exitoso'
      case 'en cola':
      case 'En Cola':
      case 'Por confirmar':
      case 'En Espera':
      case 'En espera':
      case 'Por Cobrar':
      case 'Notificado':
      case 'Cargando':
        return 'texto-secundario'
      default:
        return 'texto-error'
    }
  }
  return ''
}

/**
 * Formatea la fecha a dd-mm-aaaa
 * @param {string} fecha // En formato aaaa-mm-dd
 * @return {string}
 */
function formatearFecha (fecha) {
  if (fecha) {
    const [ANHO, MES, DIA] = fecha.split('-')
    return `${DIA}-${MES}-${ANHO}`
  }
}

/**
 * Formatea la fecha y hora a dd-mm-aaaa hh:mm AM/PM
 * @param {string} fecha // En formato aaaa-mm-dd hh:mm:ss
 * @return {string}
 */
function formatearFechaYHora (fecha) {
  if (fecha) {
    const [FECHA, HORA] = fecha.slice(0, 16).split(' ')
    const [ANHO, MES, DIA] = FECHA.split('-')
    const [HORAS, MINUTOS] = HORA.split(':')
    let horaFormateada = ''

    if (Number(HORAS) < 12) {
      horaFormateada = `${HORAS}:${MINUTOS} AM`
    } else {
      if (Number(HORAS) === 12) {
        horaFormateada = `${HORAS}`
      } else {
        const formato12hrs = Number(HORAS) - 12
        horaFormateada = formato12hrs < 10 ? `0${formato12hrs}` : `${formato12hrs}`
      }
      horaFormateada += `:${MINUTOS} PM`
    }

    return `${DIA}-${MES}-${ANHO} ${horaFormateada}`
  }
}

/**
 * Formatea la fecha a dd-mm-aaaa
 * @param {string} fecha // En formato aaaa-mm-dd
 * @return {string}
 */
function formatearFechaSinHora (fechaConHora) {
  if (fechaConHora) {
    const fecha = fechaConHora.split(' ')
    const [ANHO, MES, DIA] = fecha[0].split('-')
    return `${DIA}-${MES}-${ANHO}`
  }
}

/**
 * Formatea la hora a hh:mm AM/PM
 * @param {string} hora // En formato aaaa-mm-dd hh:mm:ss
 * @return {string}
 */
function formatearHora (hora) {
  if (hora) {
    const [HORAS, MINUTOS] = hora.split(':')
    let horaFormateada = ''

    if (Number(HORAS) < 12) {
      horaFormateada = `${HORAS}:${MINUTOS} AM`
    } else {
      if (Number(HORAS) === 12) {
        horaFormateada = `${HORAS}`
      } else {
        const formato12hrs = Number(HORAS) - 12
        horaFormateada = formato12hrs < 10 ? `0${formato12hrs}` : `${formato12hrs}`
      }
      horaFormateada += `:${MINUTOS} PM`
    }

    return `${horaFormateada}`
  }
}

/**
 * Formatea el mes y año a mm-aaaa
 * @param {string} mes // En formato aaaa-mm
 * @return {string}
 */
function formatearMesAnho (mes) {
  if (mes) {
    const [ANHO, MES] = mes.split('-')

    return `${MES}-${ANHO}`
  }
}

/**
 * Devuelve el día actual
 * Función que regresa la fecha actual
 * @return {string}
 */
function diaActual () {
  return (new Date()).toISOString().split('T')[0]
}

/**
 * Valida días de retraso o adelanto en el pago de factura
 * retorna dias de atraso/adelanto y color correspondiente
 * @param {string} ven
 * @param {string} pag
 * @return {object}
 */
function diasEstatusFac (ven, pag) {
  let dif = 0
  let color = ''
  let dias = ''
  let fFecha2 = 0
  const venFac = formatearFecha(ven)
  const aFecha1 = venFac.split('-')
  const fFecha1 = Date.UTC(aFecha1[2], aFecha1[1] - 1, aFecha1[0])
  if (pag) {
    const pago = formatearFecha(pag)
    const aFecha2 = pago.split('-')
    fFecha2 = Date.UTC(aFecha2[2], aFecha2[1] - 1, aFecha2[0])
  } else {
    const actual = formatearFecha(diaActual())
    const aFecha2 = actual.split('-')
    fFecha2 = Date.UTC(aFecha2[2], aFecha2[1] - 1, aFecha2[0])
  }
  dif = fFecha2 - fFecha1
  const total = Math.floor(dif / (1000 * 60 * 60 * 24))
  if (pag) {
    if (total <= 0) {
      dias = 'Adelantado ' + Math.abs(total)
      color = 'texto-exitoso'
    } else {
      dias = 'Atrasado ' + Math.abs(total)
      color = 'texto-error'
    }
  } else if (total <= 0) {
    dias = 'Faltan ' + Math.abs(total)
    color = 'texto-exitoso'
  } else {
    dias = 'Pasaron ' + Math.abs(total)
    color = 'texto-error'
  }
  return { dias, color }
}

/**
 * Comprueba si se está mostrando una ruta hija
 * @param {string} ruta
 * @return {boolean}
 */
function comprobarRutaHija (ruta) {
  if (ruta) {
    return !!(ruta[1] && ruta[1].length) // Si existe ruta hija return true
  } else return false
}

export {
  convertirNumeroAFixed,
  comprobarRutaHija,
  diaActual,
  diasEstatusFac,
  validarTexto,
  capitalizarPalabras,
  asignarColorEstado,
  formatearFecha,
  formatearFechaYHora,
  formatearFechaSinHora,
  formatearHora,
  formatearMesAnho
}
